<template>
  <div class="app-container">
    <div class="top_box">
      <el-form class="el-form-box" ref="formMessage" :model="formMessage" v-loading="formMessageLoading"
        @submit.native.prevent>
        <el-form-item label="" prop="key">
          <el-input v-model="formMessage.key" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }"
            placeholder="请输入要翻译的内容" />
        </el-form-item>
      </el-form>
      <div>
        <el-dropdown trigger="click" @command="clickDropdown">
          <span class="el-dropdown-link">
            <el-button type="primary" size="medium">{{ languageValue }} <i
                class="el-icon-arrow-down el-icon--right"></i></el-button>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="中文">中文</el-dropdown-item>
            <el-dropdown-item command="英语">英语</el-dropdown-item>
            <el-dropdown-item command="日语">日语</el-dropdown-item>
            <el-dropdown-item command="法语">法语</el-dropdown-item>
            <el-dropdown-item command="德语">德语</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button slot="append" :icon="submitIcon" type="primary" size="medium" v-preventReClick
          @click="send">翻译</el-button>
      </div>

    </div>
    <div class="showarea">
      {{ messages }}
    </div>
  </div>
</template>
<script>
import { chat } from '@/api/ai/chat'
import { marked } from 'marked'
import ImageUpload from '@/components/ImageUpload/index'
import hljs from "highlight.js" // 引入 highlight.js
import 'highlight.js/styles/monokai-sublime.css'
export default {
  name: 'AiChat',
  data() {
    return {
      submitIcon: '',
      formMessageLoading: false,
      formMessage: {
        key: undefined,
        history: [],
        prompt: ''
      },
      languageValue: '英语',
      messages: ''
    }
  },
  mounted() { },
  methods: {
    markedText(code) {
      var rendererMD = new marked.Renderer()
      marked.setOptions({
        renderer: rendererMD,
        highlight: function (code) {
          return hljs.highlightAuto(code).value
        },
        pedantic: false,
        gfm: true,
        tables: true,
        breaks: false,
        sanitize: false,
        smartLists: true,
        smartypants: false,
        xhtml: false
      })
      return marked(code)
    },
    clickDropdown(command) {
      // 选择语言
      this.languageValue = command
    },
    send() {
      // 翻译按钮
      if (this.submitIcon == 'el-icon-loading') return
      if (!this.formMessage.key) return
      this.submitIcon = 'el-icon-loading'
      chat({
        key: '请翻译成' + this.languageValue + ':' + this.formMessage.key,
        history: [],
        prompt: '翻译工具'
      }).then((res) => {
        if (res.data.success) {
          // 找到最后一个message，进行content替换
          this.messages = res.data.result
          this.submitIcon = ''
        } else {
          this.$msgError(res.data.message)
        }
      })
    }
  },
  components: {
    ImageUpload // 注册上传组建
  }
}
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}

.app-container {
  overflow: hidden;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.top_box {
  margin-top: 10px;
}

.el-form-box {
  overflow: hidden;
  background: #fff;
  margin: 0 auto;
}

.el-form-item {
  margin-bottom: 10px;
}

.showarea {
  border-top: 1px solid #DCDFE6;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding-top: 20px;
  margin-top: 20px;
  line-height: 24px;
}

@media only screen and (max-width: 768px) {
  [class*="top_box"] {
    width: 100%;
    background: #fff;
  }

  [class*="showarea"] {
    width: 100%;
  }

  [class*="el-form-box"] {
    width: 100%;
  }

  [class*="showarea"] {
    width: 100%;
  }
}

.el-main {
  padding: 10px;
}
</style>